define("up2tom-admin/adapters/application", ["exports", "ember-data", "ember-simple-auth-token/mixins/token-authorizer", "up2tom-admin/config/environment"], function (_exports, _emberData, _tokenAuthorizer, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPIAdapter.extend(_tokenAuthorizer.default, {
    host: _environment.default.BACKEND_HOST,
    namespace: '/api'
  });

  _exports.default = _default;
});