define("up2tom-admin/templates/management/models/model", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c96CyByK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row wrapper border-bottom white-bg page-heading\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-lg-10\"],[9],[0,\"\\n    \"],[7,\"h2\"],[9],[0,\"Model\"],[10],[0,\"\\n    \"],[7,\"ol\"],[11,\"class\",\"breadcrumb\"],[9],[0,\"\\n      \"],[7,\"li\"],[11,\"class\",\"breadcrumb-item\"],[9],[0,\"\\n        \"],[4,\"link-to\",null,[[\"route\"],[\"management\"]],{\"statements\":[[0,\"Home\"]],\"parameters\":[]},null],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"li\"],[11,\"class\",\"breadcrumb-item\"],[9],[0,\"\\n        \"],[4,\"link-to\",null,[[\"route\"],[\"management.models\"]],{\"statements\":[[0,\"Models\"]],\"parameters\":[]},null],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"li\"],[11,\"class\",\"breadcrumb-item active\"],[9],[0,\"\\n        \"],[7,\"strong\"],[9],[1,[25,[\"model\",\"name\"]],false],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"wrapper wrapper-content animated fadeIn\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"col-lg-12\"],[9],[0,\"\\n      \"],[1,[29,\"management/models/model/model-overview-ibox\",null,[[\"model\"],[[25,[\"model\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "up2tom-admin/templates/management/models/model.hbs"
    }
  });

  _exports.default = _default;
});