define("up2tom-admin/controllers/management/models/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    router: Ember.inject.service(),
    queryParams: ['search'],
    search: null,
    actions: {
      goToModel: function goToModel(model) {
        this.router.transitionTo('management.models.model', model);
      }
    }
  });

  _exports.default = _default;
});