define("up2tom-admin/router", ["exports", "up2tom-admin/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('login');
    this.route('management', {
      path: ''
    }, function () {
      this.route('users');
      this.route('invites');
      this.route('models', function () {
        this.route('model', {
          path: '/:model_id'
        });
        this.route('report', {
          path: '/report/:model_id'
        });
      });
      this.route('tokens');
      this.route('audit');
      this.route('batch');
    });
  });
  var _default = Router;
  _exports.default = _default;
});