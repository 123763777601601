define("up2tom-admin/routes/application", ["exports", "ember-simple-auth/mixins/application-route-mixin", "@sentry/browser"], function (_exports, _applicationRouteMixin, Sentry) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    toast: Ember.inject.service(),
    sessionAuthenticated: function sessionAuthenticated() {
      this._super.apply(this, arguments);

      var _this$session$data$au = this.session.data.authenticated,
          email = _this$session$data$au.email,
          username = _this$session$data$au.username,
          id = _this$session$data$au.id;
      Sentry.configureScope(function (scope) {
        scope.setUser({
          email: email,
          id: id,
          username: username
        });
      });
    }
  });

  _exports.default = _default;
});