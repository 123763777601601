define("up2tom-admin/components/pagination-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'nav',
    actions: {
      next: function next() {
        var current = this.current,
            total = this.total;

        if (current < total) {
          this.incrementProperty('current');

          if (this.change) {
            this.change();
          }
        }
      },
      prev: function prev() {
        var current = this.current;

        if (current > 1) {
          this.decrementProperty('current');

          if (this.change) {
            this.change();
          }
        }
      },
      goTo: function goTo(page) {
        if (page != this.current) {
          this.set('current', page);

          if (this.change) {
            this.change();
          }
        }
      }
    }
  });

  _exports.default = _default;
});