define("up2tom-admin/templates/management", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yJo0Ts4u",
    "block": "{\"symbols\":[],\"statements\":[[4,\"inspinia-wrapper\",null,null,{\"statements\":[[0,\"  \"],[1,[23,\"outlet\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "up2tom-admin/templates/management.hbs"
    }
  });

  _exports.default = _default;
});