define("up2tom-admin/templates/components/paginated-table/table-body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZCZDrjCR",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[15,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "up2tom-admin/templates/components/paginated-table/table-body.hbs"
    }
  });

  _exports.default = _default;
});