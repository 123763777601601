define("up2tom-admin/templates/components/nav-top", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GnKszJJL",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"navbar-header\"],[9],[0,\"\\n  \"],[7,\"a\"],[11,\"class\",\"navbar-minimalize minimalize-styl-2 btn btn-primary\"],[11,\"href\",\"#\"],[9],[0,\"\\n    \"],[1,[29,\"fa-icon\",[\"bars\"],null],false],[0,\"\\n  \"],[3,\"action\",[[24,0,[]],\"toggleSidebar\"]],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"ul\"],[11,\"class\",\"nav navbar-top-links navbar-right\"],[9],[0,\"\\n  \"],[7,\"li\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"m-r-sm text-muted welcome-message\"],[9],[1,[25,[\"session\",\"data\",\"authenticated\",\"username\"]],false],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"li\"],[9],[0,\"\\n    \"],[7,\"a\"],[11,\"href\",\"#\"],[9],[0,\"\\n      \"],[1,[29,\"fa-icon\",[\"sign-out-alt\"],null],false],[0,\"\\n      Log out\\n    \"],[3,\"action\",[[24,0,[]],[25,[\"session\",\"invalidate\"]]]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "up2tom-admin/templates/components/nav-top.hbs"
    }
  });

  _exports.default = _default;
});