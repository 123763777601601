define("up2tom-admin/components/select-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'select',
    change: function change(e) {
      var val = this.$(e.target).val();
      var v = Number(val) || val;
      this.set('value', v);

      if (this.action) {
        this.action(v);
      }
    }
  });

  _exports.default = _default;
});