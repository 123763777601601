define("up2tom-admin/models/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    createdBy: _emberData.default.belongsTo('user'),
    metadata: _emberData.default.belongsTo('metadata'),
    exclusions: _emberData.default.belongsTo('exclusions'),
    created: _emberData.default.attr('date'),
    measurements: _emberData.default.attr(),
    datasetInfo: _emberData.default.belongsTo('dataset-info'),
    dataset: _emberData.default.belongsTo('patterns'),
    download: function download(token) {
      var adapter = this.store.adapterFor(this.constructor.modelName);
      return adapter.download(this, token);
    }
  });

  _exports.default = _default;
});