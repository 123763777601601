define("up2tom-admin/components/nav-top", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    classNames: ['navbar navbar-static-top'],
    tagName: 'nav',
    actions: {
      toggleSidebar: function toggleSidebar() {
        this.toggleProperty('showSidebar');
      }
    }
  });

  _exports.default = _default;
});