define("up2tom-admin/templates/components/sortable-table-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TEOBYTER",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\"],[11,\"role\",\"button\"],[11,\"class\",\"clickable\"],[9],[0,\"\\n\"],[4,\"if\",[[29,\"eq\",[[25,[\"column\"]],[25,[\"field\"]]],null]],null,{\"statements\":[[4,\"if\",[[25,[\"direction\"]]],null,{\"statements\":[[0,\"      \"],[1,[29,\"fa-icon\",[\"chevron-down\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[29,\"fa-icon\",[\"chevron-up\"],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"  \"],[1,[23,\"label\"],false],[0,\"\\n\"],[3,\"action\",[[24,0,[]],\"click\"]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "up2tom-admin/templates/components/sortable-table-header.hbs"
    }
  });

  _exports.default = _default;
});