define("up2tom-admin/components/chosen-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.$('.chosen-select').chosen({
        width: '100%'
      }).change(function (e, params) {
        var val = _this.value || [];

        if (params.selected) {
          val.push(params.selected);
        } else if (params.deselected) {
          val = val.filter(function (_) {
            return _ != params.deselected;
          });
        }

        _this.set('value', _toConsumableArray(new Set(val)));
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.$('.chosen-select').chosen('destroy');

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});