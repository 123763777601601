define("up2tom-admin/templates/components/pdf-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qDljlVT+",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n\"],[4,\"unless\",[[25,[\"dataUri\"]]],null,{\"statements\":[[0,\"    \"],[15,1],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"],[7,\"object\"],[12,\"data\",[23,\"dataUri\"]],[11,\"class\",\"full-height w-100\"],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "up2tom-admin/templates/components/pdf-component.hbs"
    }
  });

  _exports.default = _default;
});