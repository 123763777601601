define("up2tom-admin/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    username: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    firstName: _emberData.default.attr('string'),
    lastName: _emberData.default.attr('string'),
    organisation: _emberData.default.attr('string'),
    lastLogin: _emberData.default.attr('date'),
    isActive: _emberData.default.attr('boolean'),
    isSuperuser: _emberData.default.attr('boolean'),
    isStaff: _emberData.default.attr('boolean'),
    permissions: _emberData.default.attr(),
    groups: _emberData.default.attr(),
    fullName: Ember.computed('firstName', 'lastName', function () {
      var firstName = this.firstName,
          lastName = this.lastName;

      if (firstName && lastName) {
        return "".concat(firstName || "", " ").concat(lastName || "");
      } else {
        return "";
      }
    })
  });

  _exports.default = _default;
});