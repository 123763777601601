define("up2tom-admin/components/management/models/model/exclusions-overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var predicate = function predicate(filter, attributes, show) {
    return function (exclusion) {
      var text = filter.toLowerCase();

      switch (exclusion._cls) {
        case 'ValueEx':
          return show.showValueEx && [exclusion.antecedent[0], exclusion.consequent[0]].some(function (r) {
            var question = attributes[r.index].question;
            return r.threshold.toString().toLowerCase().includes(text) || question.toLowerCase().includes(text);
          });

        case 'BlatantEx':
          return show.showBlatantEx && [exclusion.antecedent, exclusion.consequent].some(function (r) {
            var v = r._cls === 'ClassRes' ? r.value : r.threshold;
            var left = v.toString().toLowerCase().includes(text);

            if (r.hasOwnProperty('index')) {
              var question = attributes[r.index].question;
              return left || question.toLowerCase().includes(text);
            }

            return left;
          });

        case 'RelationshipEx':
          return show.showRelationshipEx && [attributes[exclusion.relation.index], attributes[exclusion.relation.threshold]].some(function (a) {
            return a.question.toLowerCase().includes(text);
          });

        default:
          return false;
      }
    };
  };

  var _default = Ember.Component.extend({
    showBlatantEx: true,
    showValueEx: true,
    showRelationshipEx: true,
    exclusionsWithIndex: Ember.computed('model.{exclusions.{obj,obj.@each}}', function () {
      var obj = this.get('model.exclusions.obj') || [];
      return obj.map(function (e, i) {
        return [e, i];
      });
    }),
    filteredExclusions: Ember.computed('filter', 'exclusionsWithIndex', 'exclusionsWithIndex.@each', 'showBlatantEx', 'showValueEx', 'showRelationshipEx', function () {
      var filter = this.filter || '';
      var metadata = this.get('model.metadata');
      var es = this.exclusionsWithIndex;
      var show = this.getProperties('showBlatantEx', 'showValueEx', 'showRelationshipEx');

      if (es && metadata) {
        var attributes = metadata.get('attributes');
        var filtered = es.filter(function (_ref) {
          var _ref2 = _slicedToArray(_ref, 1),
              e = _ref2[0];

          return predicate(filter, attributes, show)(e);
        });
        return filtered;
      }

      return es;
    }),
    actions: {
      deleteExclusion: function deleteExclusion(exclusion, index) {
        var exclusions = this.get('model.exclusions');
        exclusions.set('obj', exclusions.get('obj').filter(function (e, i) {
          return i != index;
        }));
        this.get('model.exclusions').notifyPropertyChange('obj');
      }
    }
  });

  _exports.default = _default;
});