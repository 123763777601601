define("up2tom-admin/components/pdf-component", ["exports", "jspdf"], function (_exports, _jspdf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var _this = this;

      var doc = new _jspdf.default();
      doc.setProperties({
        title: this.title
      });

      var cb = function cb(_ref) {
        var ready = _ref.ready;

        if (ready) {
          var dataUri = doc.output('datauristring');

          _this.set('dataUri', dataUri);
        }
      };

      var content = this.$('.content');
      content.css('max-width: 10px');
      doc.fromHTML(content[0], 10, 10, {}, cb);
    }
  });

  _exports.default = _default;
});