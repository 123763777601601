define("up2tom-admin/templates/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JwrLKkL6",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"page-wrapper gray-bg full-height\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"middle-box text-center loginscreen animated fadeInDown\"],[9],[0,\"\\n    \"],[7,\"div\"],[9],[0,\"\\n      \"],[7,\"h3\"],[9],[0,\"UP2TOM ADMIN\"],[10],[0,\"\\n      \"],[7,\"form\"],[11,\"class\",\"m-t\"],[11,\"role\",\"form\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"form-group\"],[9],[0,\"\\n          \"],[1,[29,\"input\",null,[[\"class\",\"value\",\"required\",\"placeholder\"],[\"form-control\",[25,[\"username\"]],true,\"Username\"]]],false],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"form-group\"],[9],[0,\"\\n          \"],[1,[29,\"input\",null,[[\"type\",\"class\",\"value\",\"required\",\"placeholder\",\"enter\"],[\"password\",\"form-control\",[25,[\"password\"]],true,\"Password\",[29,\"perform\",[[25,[\"login\"]]],null]]]],false],[0,\"\\n        \"],[10],[0,\"\\n\"],[4,\"ui-button\",null,[[\"style\",\"onClick\"],[\"primary block\",[29,\"perform\",[[25,[\"login\"]]],null]]],{\"statements\":[[0,\"          Login\\n\"]],\"parameters\":[]},null],[0,\"        \"],[7,\"div\"],[11,\"class\",\"mt-2\"],[9],[0,\"\\n          \"],[7,\"a\"],[11,\"href\",\"#\"],[9],[7,\"small\"],[9],[0,\"Forgot password?\"],[10],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "up2tom-admin/templates/login.hbs"
    }
  });

  _exports.default = _default;
});