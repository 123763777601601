define("up2tom-admin/templates/components/management/models/model/assessments-overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YgSVbp4s",
    "block": "{\"symbols\":[\"assessment\"],\"statements\":[[4,\"if\",[[25,[\"task\",\"isRunning\"]]],null,{\"statements\":[[0,\"  \"],[7,\"p\"],[9],[0,\"Loading...\"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"assessments\",\"length\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"table-responsive\"],[9],[0,\"\\n      \"],[7,\"table\"],[11,\"class\",\"table table-striped\"],[9],[0,\"\\n        \"],[7,\"thead\"],[9],[0,\"\\n          \"],[7,\"th\"],[9],[0,\"Assessment date\"],[10],[0,\"\\n          \"],[7,\"th\"],[9],[0,\"User\"],[10],[0,\"\\n          \"],[7,\"th\"],[9],[0,\"Assessment Size\"],[10],[0,\"\\n          \"],[7,\"th\"],[9],[0,\"Consistency\"],[10],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"tbody\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"assessments\"]]],null,{\"statements\":[[4,\"if\",[[29,\"not\",[[24,1,[\"isLoading\"]]],null]],null,{\"statements\":[[0,\"              \"],[7,\"tr\"],[9],[0,\"\\n                \"],[7,\"td\"],[9],[1,[29,\"moment-format\",[[24,1,[\"created\"]],\"DD/MM/YYYY HH:mm\"],null],false],[10],[0,\"\\n                \"],[7,\"td\"],[9],[1,[24,1,[\"user\",\"username\"]],false],[10],[0,\"\\n                \"],[7,\"td\"],[9],[0,\"\\n                  \"],[7,\"a\"],[11,\"href\",\"#\"],[9],[0,\"\\n                    \"],[1,[24,1,[\"points\",\"obj\",\"length\"]],false],[0,\"\\n                  \"],[3,\"action\",[[24,0,[]],\"exportAssessment\",[24,1,[]]]],[10],[0,\"\\n                \"],[10],[0,\"\\n                \"],[7,\"td\"],[9],[1,[29,\"round\",[[24,1,[\"consistency\"]]],null],false],[0,\"%\"],[10],[0,\"\\n              \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"p\"],[9],[0,\"N/A\"],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "up2tom-admin/templates/components/management/models/model/assessments-overview.hbs"
    }
  });

  _exports.default = _default;
});