define("up2tom-admin/adapters/model", ["exports", "up2tom-admin/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    download: function download(model, token) {
      var url = this.buildURL('model', model.get('id')) + "/download";
      return new Promise(function (resolve, reject) {
        var req = new XMLHttpRequest();
        req.responseType = "arraybuffer";

        req.onreadystatechange = function () {
          if (this.readyState == 4) {
            if (this.status == 200) {
              resolve(req.response);
            } else {
              reject();
            }
          }
        };

        req.open("GET", url, true);
        req.setRequestHeader('Authorization', "Bearer ".concat(token));
        req.send();
      });
    }
  });

  _exports.default = _default;
});