define("up2tom-admin/controllers/management/invites", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    actions: {
      newInvite: function newInvite() {
        var invite = this.store.createRecord('invite', {
          coupons: 10
        });
        this.set('selectedInvite', invite);
      }
    }
  });

  _exports.default = _default;
});