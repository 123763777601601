define("up2tom-admin/templates/components/ui-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fj7yt3vS",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"button\"],[12,\"class\",[29,\"concat\",[\"btn \",[29,\"if\",[[29,\"contains\",[\"white\",[29,\"w\",[[25,[\"style\"]]],null]],null],\"btn-white \"],null],[29,\"if\",[[29,\"contains\",[\"primary\",[29,\"w\",[[25,[\"style\"]]],null]],null],\"btn-primary \"],null],[29,\"if\",[[29,\"contains\",[\"danger\",[29,\"w\",[[25,[\"style\"]]],null]],null],\"btn-danger \"],null],[29,\"if\",[[29,\"contains\",[\"warning\",[29,\"w\",[[25,[\"style\"]]],null]],null],\"btn-warning \"],null],[29,\"if\",[[29,\"contains\",[\"block\",[29,\"w\",[[25,[\"style\"]]],null]],null],\"btn-block \"],null],[29,\"if\",[[29,\"contains\",[\"lg\",[29,\"w\",[[25,[\"style\"]]],null]],null],\"btn-lg \"],null],[29,\"if\",[[29,\"contains\",[\"rounded\",[29,\"w\",[[25,[\"style\"]]],null]],null],\"rounded \"],null]],null]],[12,\"disabled\",[29,\"or\",[[25,[\"isDisabled\"]],[25,[\"clickTask\",\"isRunning\"]]],null]],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"clickTask\",\"isRunning\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"fa-icon\",[\"spinner\"],[[\"spin\",\"class\"],[true,\"mr-1\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"icon\"]]],null,{\"statements\":[[0,\"    \"],[1,[29,\"fa-icon\",[[25,[\"icon\"]]],[[\"class\"],[\"mr-1\"]]],false],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"  \"],[15,1],[0,\"\\n\"],[3,\"action\",[[24,0,[]],[29,\"perform\",[[25,[\"clickTask\"]]],null]]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "up2tom-admin/templates/components/ui-button.hbs"
    }
  });

  _exports.default = _default;
});