define("up2tom-admin/helpers/paginator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.paginator = paginator;
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance"); }

  function _iterableToArray(iter) { if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function paginator(params
  /*, hash*/
  ) {
    var _params = _slicedToArray(params, 2),
        current = _params[0],
        total = _params[1];

    var delta = 2;
    var left = current - delta;
    var right = current + delta + 1;

    var range = _toConsumableArray(Array(total).keys()).map(function (_) {
      return _ + 1;
    }).filter(function (i) {
      return i == 1 || i == total || i >= left && i < right;
    });

    return range.reduce(function (acc, i) {
      var _acc = _slicedToArray(acc, 2),
          rangeWithDots = _acc[0],
          l = _acc[1];

      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push('...');
        }
      }

      rangeWithDots.push(i);
      l = i;
      return [rangeWithDots, l];
    }, [[], null])[0];
  }

  var _default = Ember.Helper.helper(paginator);

  _exports.default = _default;
});