define("up2tom-admin/templates/components/inspinia-wrapper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f2xn1TEO",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[29,\"nav-side\",null,[[\"show\"],[[25,[\"showSidebar\"]]]]],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"id\",\"page-wrapper\"],[11,\"class\",\"gray-bg\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row border-bottom\"],[9],[0,\"\\n    \"],[1,[29,\"nav-top\",null,[[\"showSidebar\"],[[25,[\"showSidebar\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\\n  \"],[15,1],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"footer\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"float-right\"],[9],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[9],[0,\"\\n      \"],[7,\"strong\"],[9],[0,\"Copyright\"],[10],[0,\" Merlynn Intelligence Technologies © 2005-2019\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "up2tom-admin/templates/components/inspinia-wrapper.hbs"
    }
  });

  _exports.default = _default;
});