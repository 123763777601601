define("up2tom-admin/serializers/application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPISerializer.extend({
    modelNameFromPayloadKey: function modelNameFromPayloadKey(payloadKey) {
      switch (payloadKey) {
        case 'metadata':
          return 'metadata';

        case 'exclusions':
          return 'exclusions';

        case 'patterns':
          return 'patterns';

        default:
          return this._super(payloadKey);
      }
    }
  });

  _exports.default = _default;
});