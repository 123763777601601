define("up2tom-admin/templates/components/management/models/model/stats-overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AGqle/uX",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-lg-6\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"ibox loading chart\"],[9],[0,\"\\n      \"],[7,\"div\"],[12,\"class\",[30,[\"ibox-content \",[29,\"if\",[[25,[\"loadDatasetTask\",\"isRunning\"]],\"sk-loading\"],null]]]],[9],[0,\"\\n        \"],[7,\"h3\"],[11,\"class\",\"mb-3\"],[9],[0,\"Class distribution\"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"sk-spinner sk-spinner-double-bounce\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"sk-double-bounce1\"],[9],[10],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"sk-double-bounce2\"],[9],[10],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[9],[0,\"\\n          \"],[7,\"canvas\"],[11,\"id\",\"classDistributionChart\"],[11,\"height\",\"140\"],[9],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-lg-6\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"ibox loading chart\"],[9],[0,\"\\n      \"],[7,\"div\"],[12,\"class\",[30,[\"ibox-content \",[29,\"if\",[[25,[\"loadDatasetTask\",\"isRunning\"]],\"sk-loading\"],null]]]],[9],[0,\"\\n        \"],[7,\"h3\"],[11,\"class\",\"mb-3\"],[9],[0,\"Training Progress\"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"sk-spinner sk-spinner-double-bounce\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"sk-double-bounce1\"],[9],[10],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"sk-double-bounce2\"],[9],[10],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[9],[0,\"\\n          \"],[7,\"canvas\"],[11,\"id\",\"accuracyChart\"],[11,\"height\",\"140\"],[9],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "up2tom-admin/templates/components/management/models/model/stats-overview.hbs"
    }
  });

  _exports.default = _default;
});