define("up2tom-admin/session-stores/application", ["exports", "ember-simple-auth/session-stores/adaptive"], function (_exports, _adaptive) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _adaptive.default.extend({
    cookieName: 'up2tom-admin',
    localStorageKey: 'up2tom-admin'
  });

  _exports.default = _default;
});