define("up2tom-admin/services/blob-downloader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    download: function download(s) {
      var filename = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'download';
      var blob = new Blob([s]);

      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename);
      } else {
        var a = window.document.createElement("a");
        a.href = window.URL.createObjectURL(blob);
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    }
  });

  _exports.default = _default;
});