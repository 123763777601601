define("up2tom-admin/helpers/human-readable-domain", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.humanReadableDomain = humanReadableDomain;
  _exports.default = void 0;

  function humanReadableDomain(params
  /*, hash*/
  ) {
    switch (params[0]) {
      case 'DomainR':
        return 'Numeric';

      case 'DomainC':
        return 'Categorical';
    }

    return params;
  }

  var _default = Ember.Helper.helper(humanReadableDomain);

  _exports.default = _default;
});