define("up2tom-admin/components/nav-side", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    tagName: 'nav',
    classNames: ['navbar-default navbar-static-side'],
    showSidebarObserver: Ember.observer('show', function () {
      (0, _jquery.default)("body").toggleClass("mini-navbar");

      if (!(0, _jquery.default)('body').hasClass('mini-navbar') || (0, _jquery.default)('body').hasClass('body-small')) {
        // Hide menu in order to smoothly turn on when maximize menu
        (0, _jquery.default)('#side-menu').hide(); // For smoothly turn on menu

        setTimeout(function () {
          (0, _jquery.default)('#side-menu').fadeIn(400);
        }, 200);
      } else if ((0, _jquery.default)('body').hasClass('fixed-sidebar')) {
        (0, _jquery.default)('#side-menu').hide();
        setTimeout(function () {
          (0, _jquery.default)('#side-menu').fadeIn(400);
        }, 100);
      } else {
        // Remove all inline style from jquery fadeIn function to reset menu state
        (0, _jquery.default)('#side-menu').removeAttr('style');
      }
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.$('.metisMenu').metisMenu();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.$('.metisMenu').metisMenu('dispose');
    }
  });

  _exports.default = _default;
});