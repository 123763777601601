define("up2tom-admin/templates/components/pagination-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9a7s10ej",
    "block": "{\"symbols\":[\"page\"],\"statements\":[[7,\"p\"],[11,\"class\",\"float-left\"],[9],[0,\"Page \"],[1,[23,\"current\"],false],[0,\" of \"],[1,[23,\"total\"],false],[10],[0,\"\\n\"],[7,\"ul\"],[11,\"class\",\"pagination justify-content-end\"],[9],[0,\"\\n  \"],[7,\"li\"],[12,\"class\",[30,[\"page-item \",[29,\"if\",[[29,\"eq\",[[25,[\"current\"]],1],null],\"disabled\"],null]]]],[9],[0,\"\\n    \"],[7,\"a\"],[11,\"class\",\"page-link\"],[11,\"href\",\"#\"],[9],[0,\"Previous\"],[3,\"action\",[[24,0,[]],\"prev\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n\\n\"],[4,\"each\",[[29,\"paginator\",[[25,[\"current\"]],[25,[\"total\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"li\"],[12,\"class\",[30,[\"page-item \",[29,\"if\",[[29,\"eq\",[[25,[\"current\"]],[24,1,[]]],null],\"active\"],null],\" \",[29,\"if\",[[29,\"or\",[[29,\"eq\",[[25,[\"current\"]],[24,1,[]]],null],[29,\"eq\",[[24,1,[]],\"...\"],null]],null],\"disabled\"],null]]]],[9],[0,\"\\n      \"],[7,\"a\"],[11,\"role\",\"button\"],[11,\"class\",\"page-link\"],[9],[1,[24,1,[]],false],[3,\"action\",[[24,0,[]],\"goTo\",[24,1,[]]]],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n  \"],[7,\"li\"],[12,\"class\",[30,[\"page-item \",[29,\"if\",[[29,\"eq\",[[25,[\"current\"]],[25,[\"total\"]]],null],\"disabled\"],null]]]],[9],[0,\"\\n    \"],[7,\"a\"],[11,\"class\",\"page-link\"],[11,\"href\",\"#\"],[9],[0,\"Next\"],[3,\"action\",[[24,0,[]],\"next\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "up2tom-admin/templates/components/pagination-component.hbs"
    }
  });

  _exports.default = _default;
});