define("up2tom-admin/components/management/models/model/metadata-overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    levers: Ember.computed('model', 'model.{measurements,measurements.levers}', function () {
      return this.model.get('measurements.levers');
    }),
    actions: {
      editedMetadata: function editedMetadata(e) {
        this.$(e.target).blur();
        var prediction = this.get('model.metadata.prediction');
        var attributes = this.get('model.metadata.attributes');
        this.get('model.metadata').set('prediction', Object.assign({}, prediction));
        this.get('model.metadata').set('attributes', attributes.slice(0));
      }
    }
  });

  _exports.default = _default;
});