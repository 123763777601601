define("up2tom-admin/models/assessment", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    created: _emberData.default.attr('date'),
    project: _emberData.default.belongsTo('model'),
    points: _emberData.default.attr(),
    user: _emberData.default.belongsTo('user'),
    consistency: _emberData.default.attr('number')
  });

  _exports.default = _default;
});