define("up2tom-admin/templates/components/chosen-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P27LjwsD",
    "block": "{\"symbols\":[\"o\",\"@multiple\",\"@name\",\"@options\"],\"statements\":[[7,\"select\"],[11,\"data-placeholder\",\"None\"],[11,\"class\",\"chosen-select form-control\"],[12,\"multiple\",[24,2,[]]],[12,\"name\",[24,3,[]]],[9],[0,\"\\n  \"],[7,\"option\"],[11,\"value\",\"\"],[9],[10],[0,\"\\n\"],[4,\"each\",[[24,4,[]]],null,{\"statements\":[[0,\"    \"],[7,\"option\"],[12,\"value\",[24,1,[]]],[9],[1,[24,1,[]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "up2tom-admin/templates/components/chosen-select.hbs"
    }
  });

  _exports.default = _default;
});