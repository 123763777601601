define("up2tom-admin/models/batch", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    uploadDate: _emberData.default.attr('date'),
    user: _emberData.default.belongsTo('user'),
    size: _emberData.default.attr('number'),
    filename: _emberData.default.attr('string')
  });

  _exports.default = _default;
});