define("up2tom-admin/utils/max-or-min-by", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = maxOrMinBy;

  function maxOrMinBy(key, items) {
    var max = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;

    if (typeof key === 'string' && Array.isArray(items) && items.length) {
      return items.reduce(function (acc, current) {
        var value = current[key];

        if (max && value > acc[key]) {
          acc = current;
        } else if (!max && value < acc[key]) {
          acc = current;
        }

        return acc;
      }, items[0]);
    }
  }
});