define("up2tom-admin/utils/patterns", ["exports", "papaparse"], function (_exports, _papaparse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var patternToArray = function patternToArray(pattern, prediction, attributes) {
    if (pattern && prediction && attributes) {
      var row = pattern.input.map(function (v, i) {
        var attribute = attributes[i];

        if (attribute.domain.values) {
          return attribute.domain.values[Math.floor(v)];
        } else {
          return v;
        }
      });
      var output = prediction.domain.values[Math.floor(pattern.output)];
      row.push(output);
      return row;
    }
  };

  var _default = {
    patternToArray: patternToArray,
    patternsToCSV: function patternsToCSV(patterns, metadata) {
      var attributes = metadata.get('attributes');
      var prediction = metadata.get('prediction');
      var fields = attributes.map(function (_) {
        return _.question;
      });
      fields.push(metadata.get('prediction.question'));
      var data = patterns.map(function (pattern) {
        return patternToArray(pattern, prediction, attributes);
      });
      return _papaparse.default.unparse({
        fields: fields,
        data: data
      });
    }
  };
  _exports.default = _default;
});