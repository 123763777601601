define("up2tom-admin/components/management/models/model/assessments-overview", ["exports", "up2tom-admin/utils/patterns"], function (_exports, _patterns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    blobDownloader: Ember.inject.service(),
    actions: {
      exportAssessment: function exportAssessment(assessment) {
        var model = this.model;

        if (model && assessment) {
          var patterns = assessment.get('points.obj');
          var metadata = this.get('model.metadata');

          var csv = _patterns.default.patternsToCSV(patterns, metadata);

          var filename = "".concat(this.get('model.name'), "-assessment-").concat(assessment.get('id'), ".csv");
          this.blobDownloader.download(csv, filename);
        }
      }
    }
  });

  _exports.default = _default;
});