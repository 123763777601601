define("up2tom-admin/app", ["exports", "up2tom-admin/resolver", "ember-load-initializers", "up2tom-admin/config/environment", "@sentry/browser", "@sentry/integrations"], function (_exports, _resolver, _emberLoadInitializers, _environment, Sentry, Integrations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  if (_environment.default && _environment.default.sentry && _environment.default.sentry.dsn && _environment.default.sentry.release) {
    var _config$sentry = _environment.default.sentry,
        dsn = _config$sentry.dsn,
        release = _config$sentry.release;
    Sentry.init({
      dsn: dsn,
      release: release,
      integrations: [new Integrations.Ember()],
      beforeSend: function beforeSend(event) {
        // Check if it is an exception, and if so, show the report dialog
        if (event.exception) {
          var isTransitionAborted = event.exception.values.length && event.exception.values[0].type === 'TransitionAborted';

          if (!isTransitionAborted) {
            Sentry.showReportDialog({
              eventId: event.event_id
            });
          }
        }

        return event;
      }
    });
  }

  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  var _default = App;
  _exports.default = _default;
});