define("up2tom-admin/models/invite", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    email: _emberData.default.attr('string'),
    sent: _emberData.default.attr('date'),
    key: _emberData.default.attr('string'),
    coupons: _emberData.default.attr('number'),
    permissions: _emberData.default.attr(''),
    organisation: _emberData.default.attr('string'),
    groups: _emberData.default.attr()
  });

  _exports.default = _default;
});